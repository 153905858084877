aside { grid-column: 2 }

main {
    gap: 2rem;
    grid-template: min-content min-content 1fr / 1fr 15rem;
}

section { grid-row: span 3 }

td:first-child {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

th div {
    display: flex;
    justify-content: flex-end;
}

tr > :first-child {
    text-align: left;
    width: 100%;
}

tr > :last-child { text-align: right }

#authors {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    display: grid;
    gap: 1rem;
}

#authors a {
    display: grid;
    gap: 0 .5rem;
    grid-template-columns: min-content auto;
}

#authors img {
    border-radius: .25rem;
    grid-row: span 2;
    height: 3rem;
    width: 3rem;
}

#authors span {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#authors span:last-child { font-size: .75rem }

@media (max-width: 30rem) {
    aside { grid-column: 1 }

    main { grid-template-columns: 1fr }
}
